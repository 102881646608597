import { apiClient } from './apiClient'

function getAxeptio() {
    return apiClient().get('/axeptio')
}

function getHotjar() {
    return apiClient().get('/hotjar')
}

export default {
    getAxeptio,
    getHotjar
}
