import { useEffect, useState } from "react";
import {
    useStripe,
    useElements,
    CardNumberElement,
    CardCvcElement,
    CardExpiryElement,
} from "@stripe/react-stripe-js";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { useHistory, useLocation } from "react-router-dom";
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import { NotificationManager } from 'react-notifications';
import { getProductInCart } from "actions/cart";
import { useDispatch } from "react-redux";

export default function CheckoutForm(order, deliveryPrice) {
    const history = useHistory();
    const stripe = useStripe();
    const elements = useElements();
    const location = useLocation();
    const dispatch = useDispatch();

    const [isLoading, setIsLoading] = useState(false);
    const [holderName, setHolderName] = useState();

    const [error, setError] = useState();

    const clientSecret = location?.state?.secretKey;

    const options = {
        hidePostalCode: true,
        style: {
            border: '1px solid red', // Ajoutez cette ligne pour ajouter une bordure
            borderRadius: '4px', // Ajoutez cette ligne pour arrondir les coins
            padding: '13px', //
            base: {
                fontSize: '15px',
                color: '#424770',

                '::placeholder': {
                    color: '#6c7381',
                },
            },
        },
    };

    useEffect(() => {
        if (!stripe) {
            return;
        }

        if (!clientSecret) {
            return;
        }

        stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
            switch (paymentIntent.status) {
            }
        });
    }, [stripe]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!stripe || !elements) {
            return;
        }

        setIsLoading(true);

        const result = await stripe.confirmCardPayment(
            clientSecret,
            {
                payment_method: {
                    card: elements.getElement(CardNumberElement),
                    billing_details: {
                        name: holderName
                    }
                },
            }
        );

        if (result.error) {
            setError(result?.error);

            switch (result.error.code) {
                case 'card_declined':
                    NotificationManager.error('Refus de paiement générique.', '');
                    break;
                case 'expired_card':
                    NotificationManager.error('Refus de paiement pour cause de carte expirée.', '');
                    break;
                case 'incorrect_cvc':
                    NotificationManager.error('Code CVC incorrect. Veuillez vérifier le code et réessayer.', '');
                    break;
                case 'processing_error':
                    NotificationManager.error('Refus de paiement pour cause d’erreur de traitement.', '');
                    break;
                case 'incorrect_number':
                    NotificationManager.error('Refus de paiement pour cause de numéro incorrect.', '');
                    break;
                default:
                    NotificationManager.error('Une erreur de paiement s\'est produite. Veuillez réessayer.', '');
                    break;
            }
        } else if (result.paymentIntent.status === "succeeded") {
            // Le paiement a réussi, rediriger vers la confirmation
            dispatch(getProductInCart())
            history.push("/checkout/confirm", { order: order, deliveryPrice: deliveryPrice });
        }

        setIsLoading(false);
    };

    return (
        <form id="payment-form" onSubmit={handleSubmit}>
            <div className="mb-4 mt-4 md:mt-0 font-bold">
                <Label>Titulaire de la carte</Label>
                <Input onChange={(e) => setHolderName(e?.target?.value)} className="mt-1.5 text-[#424770] text-[15px]" placeholder="John Doe" />
            </div>

            <label>
                <Label>Numéro de carte</Label>
                <div className={`${error && "border-[1px] border-[#eb1c26] rounded"}`}>
                    <CardNumberElement options={options} />
                </div>
            </label>
            <div className="grid grid-cols-2 gap-4 mt-4">
                <label>
                    <Label>Date d'expiration</Label>
                    <div className={`${error && "border-[1px] border-[#eb1c26] rounded"}`}>
                        <CardExpiryElement options={options} />
                    </div>
                </label>
                <label>
                    <Label>CVC</Label>
                    <div className={`${error && "border-[1px] border-[#eb1c26] rounded"}`}>
                        <CardCvcElement options={options} />
                    </div>
                </label>
            </div>

            <div className="flex justify-end mt-6">
                <div disabled={isLoading || !stripe || !elements} id="submit">
                    <ButtonPrimary className="h-1/4 ml-4">
                        {isLoading ? (
                            <>
                                <svg
                                    className="w-7 h-7 animate-spin text-[white]"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M12 4.75V6.25"
                                        stroke="currentColor"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    ></path>
                                    {/* Les autres éléments SVG */}
                                </svg>
                            </>
                        ) : (
                            <>
                                <svg className="relative top-[-1px]" xmlns="http://www.w3.org/2000/svg" width={15} height={15} version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 421 515.3">
                                    <path fill="white" d="M198,266.8c-14,3.6-25.4,15.2-28.9,29.2c-4.6,18.4,3.2,35.3,16.7,44.5c1.6,1.1,2.6,2.8,2.6,4.7v55.3c0,3.2,2.6,5.7,5.7,5.7   h29.8c3.2,0,5.7-2.6,5.7-5.7v-55.3c0-1.9,1-3.6,2.6-4.7c10.9-7.4,18.1-19.8,18.1-33.9C250.3,280.2,225.5,259.6,198,266.8z" />
                                    <path fill="white" d="M369.1,162.7h-42.5v-38.3C326.7,55.8,270.8,0,202.2,0S77.8,55.8,77.8,124.4v38.3H51.9C23.3,162.7,0,186,0,214.6v248.8   c0,28.6,23.3,51.9,51.9,51.9h317.2c28.6,0,51.9-23.3,51.9-51.9V214.6C421,186,397.7,162.7,369.1,162.7z M115.6,124.4   c0-47.8,38.9-86.7,86.7-86.7s86.7,38.9,86.7,86.7v38.3H115.6V124.4z M383.3,463.5c0,7.8-6.4,14.2-14.2,14.2H51.9   c-7.8,0-14.2-6.4-14.2-14.2V214.6c0-7.8,6.4-14.2,14.2-14.2h317.2c7.8,0,14.2,6.4,14.2,14.2V463.5z" />
                                </svg>
                                <h1 className="ml-2">Payer maintenant</h1>
                            </>
                        )}
                    </ButtonPrimary>
                </div>
            </div>
        </form>
    );
}
