import { Document, Page, View, Text, Image } from '@react-pdf/renderer';
import { IMG_LINK } from 'config/key';
import moment from 'moment';

const Pdf = ({ order, cart, store, user }) => {
  // Contenu du PDF

  return (
    <Document>
      <Page style={page} size="A4" wrap>


        <View style={{ marginTop: '10px' }}>
          <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            {store?.logoId
              ? <Image
                style={{ width: 100, marginBottom: '10px' }}
                source={{
                  uri: IMG_LINK + store?.logoId,
                }}
              />
              : <></>
            }
            <Text style={{ fontSize: 25, marginTop: 10, marginRight: 10 }}>FACTURE</Text>
          </View>

          <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>

            <View>
              <Text style={text}>{store?.name2}</Text>
              <Text style={text}>{store?.address?.address}, {store?.address?.city} </Text>
              <Text style={text}>{store?.address?.postalCode} {store?.address?.country}</Text>

              {store?.siret &&
                <Text style={text}>SIRET: {store?.siret}</Text>
              }
            </View>
            <View>
              <Text style={text}>{user?.lastname + ' ' + user?.name}</Text>
              <Text style={text}>{user?.billingAddress?.address}</Text>
              <Text style={text}>{user?.billingAddress?.postalCode} {user?.billingAddress?.city}</Text>
              <Text style={text}>{user?.phone}</Text>
              <Text style={text}>{user?.email}</Text>
            </View>
          </View>
        </View>


        <View style={{ marginTop: '25px', marginBottom: '17px' }}>
          <Text style={text}>N° Facture: FACT-{moment(order?.createdAt).format("YYYY")}{moment(order?.createdAt).format("MM")}-{order?.publicOrderId}</Text>
          <Text style={text}>Date de la commande: {moment(order?.createdAt).format("LL")} à {moment(order?.createdAt).format("LT")}</Text>
        </View>

        <View style={tableStyle}>
          <View style={tableRowStyle} fixed>

            <View style={firstTableColHeaderStyle}>
              <Text style={tableCellHeaderStyle}>Adresse de livraison</Text>
            </View>

            <View style={tableColHeaderStyle}>
              <Text style={tableCellHeaderStyle}>Adresse de facturation</Text>
            </View>
          </View>
          <View style={tableRowStyle}>

            <View style={firstTableColStyle}>
              {order?.delivery?.relayPointInfo
                ? <Text style={tableCellStyle}>
                  {order?.delivery?.relayPointInfo?.Address?.Name1}, {order?.delivery?.relayPointInfo?.Address?.Street}, {order?.delivery?.relayPointInfo?.Address?.City}, {order?.delivery?.relayPointInfo?.Address?.ZIPCode}, {order?.delivery?.relayPointInfo?.Address?.CountryCode}
                </Text>
                : <Text style={tableCellStyle}>
                  {order?.shippingAddress?.address}, {order?.shippingAddress?.city} {order?.shippingAddress?.postalCode}, {order?.shippingAddress?.country}
                </Text>
              }
            </View>

            <View style={tableColStyle}>
              <Text style={tableCellStyle}>{order?.billingAddress?.address}, {order?.billingAddress?.city} {order?.billingAddress?.postalCode}, {order?.billingAddress?.country}</Text>
            </View>

          </View>
        </View>

        <View style={tableStyle}>
          <View style={tableRowStyle} fixed>

            <View style={firstTableColHeaderStyle}>
              <Text style={tableCellHeaderStyle}>Méthode de paiement</Text>
            </View>

            <View style={tableColHeaderStyle}>
              <Text style={tableCellHeaderStyle}>Méthode de livraison</Text>
            </View>
          </View>
          <View style={tableRowStyle}>

            <View style={firstTableColStyle}>
              <Text style={tableCellStyle}>Stripe</Text>
            </View>

            <View style={tableColStyle}>
              <Text style={tableCellStyle}>{order?.delivery?.delivery?.name}</Text>
            </View>

          </View>
        </View>


        <View style={tableStyle}>
          <View style={tableRowStyle} fixed>

            <View style={[productTableColHeaderStyle, { backgroundColor: null }]}>
              <Text style={tableCellHeaderStyle}>Code</Text>
            </View>

            <View style={colHeaderStyleName}>
              <Text style={tableCellHeaderStyle}>Nom</Text>
            </View>

            <View style={colHeaderStyle}>
              <Text style={tableCellHeaderStyle}>P.U. HT</Text>
            </View>

            <View style={colHeaderStyle}>
              <Text style={tableCellHeaderStyle}>Remise</Text>
            </View>

            <View style={colHeaderStyle}>
              <Text style={tableCellHeaderStyle}>Quantité</Text>
            </View>

            <View style={colHeaderStyle}>
              <Text style={tableCellHeaderStyle}>TVA</Text>
            </View>


            <View style={colHeaderStyle}>
              <Text style={tableCellHeaderStyle}>Montant HT</Text>
            </View>
          </View>

          {Object.keys(cart?.item).map((key) => (
            <View key={key} style={tableRowStyle}>

              <View style={[colHeaderStyleSKU, { borderLeftWidth: 1, backgroundColor: "white" }]}>
                <Text style={tableCellStyle}>{cart?.item[key]?.itemRef?.sku}</Text>
              </View>

              <View style={[colHeaderStyleSKU, { backgroundColor: 'white' }]}>
                <Text style={tableCellStyle}>{cart?.item[key]?.name}</Text>
              </View>

              <View style={[colHeaderStyle, { backgroundColor: 'white' }]}>
                <Text style={[tableCellStyle, { fontSize: '10px' }]}>{order?.cart?.item[key]?.price?.toFixed(2) + ' €'}</Text>
              </View>

              <View style={[colHeaderStyle, { backgroundColor: 'white' }]}>
                <Text style={tableCellStyle}>
                  {order?.cart?.item[key]?.priceWithDiscount
                    ? `${(order?.cart?.item[key]?.price - order?.cart?.item[key]?.priceWithDiscount)?.toFixed(2)} €`
                    : '/'}
                  {order?.discount?.amount && ` (${order?.discount?.amount} ${order?.discount?.type === "percentage" ? "%" : "€"})`}
                </Text>
              </View>

              <View style={[colHeaderStyle, { backgroundColor: 'white' }]}>
                <Text style={tableCellStyle}>{cart?.item[key].quantity}</Text>
              </View>

              <View style={[colHeaderStyle, { backgroundColor: 'white' }]}>
                <Text style={tableCellStyle}>{order.cart.item[key]?.taxeRate} %</Text>
              </View>


              <View style={[colHeaderStyle, { backgroundColor: 'white' }]}>
                <Text style={tableCellStyle}>{cart?.item[key]?.totalPriceWithoutTaxe?.toFixed(2)} €</Text>
              </View>

            </View>
          ))}

          <View>
            <View style={pricingTableStyle}>
              <View style={tableRowStyle}>
                <View style={pricingTableColStyle}>
                  <Text style={tableCellStyle}>Total HT</Text>
                </View>

                <View style={pricingColStyle}>
                  <Text style={tableCellStyle}>{order?.discountedPriceWithoutTaxe ? order?.discountedPriceWithoutTaxe?.toFixed(2) : order?.cart?.totalPriceWithoutTaxe?.toFixed(2)} €</Text>
                </View>
              </View>

              <View style={tableRowStyle}>
                <View style={[pricingTableColStyle, { borderTopWidth: 0 }]}>
                  <Text style={tableCellStyle}>Total TVA</Text>
                </View>

                <View style={colStyle}>
                  <Text style={tableCellStyle}>{order?.taxePrice?.toFixed(2)} €</Text>
                </View>
              </View>

              <View style={tableRowStyle}>
                <View style={[pricingTableColStyle, { borderTopWidth: 0 }]}>
                  <Text style={tableCellStyle}>Total TTC</Text>
                </View>

                <View style={[pricingColStyle, { borderTopWidth: 0 }]}>
                  <Text style={tableCellStyle}>{order?.price?.toFixed(2)} €</Text>
                </View>
              </View>

              <View style={tableRowStyle}>
                <View style={[pricingTableColStyle, { borderTopWidth: 0 }]}>
                  <Text style={tableCellStyle}>Transport</Text>
                </View>

                <View style={colStyle}>
                  <Text style={tableCellStyle}>{order?.deliveryPrice} €</Text>
                </View>
              </View>

              <View style={tableRowStyle}>
                <View style={[pricingTableColStyle, { borderTopWidth: 0 }]}>
                  <Text style={tableCellStyle}>NET À PAYER</Text>
                </View>

                <View style={colStyle}>
                  <Text style={tableCellStyle}>{order?.totalPrice?.toFixed(2)} €</Text>
                </View>
              </View>
            </View>
          </View>

          <View style={{ marginTop: 14 }}>
            <Text size={10} style={{ fontSize: '10px' }}>Nous nous réservons la propriété des marchandises jusqu'au paiement intégral de celles-ci (loi 80-335 du 2 mal 1980)<br /></Text>
            <Text size={10} style={{ fontSize: '10px' }}>Escompte pour règlement anticipé : 0%<br /></Text>
            <Text size={10} style={{ fontSize: '10px' }}>Toute somme non payés à son échéance produira un intérêt de plein droit au taux de 10,25 % annuel.<br /></Text>
            <Text size={10} style={{ fontSize: '10px' }}>Pour les professionnets, une indemnité minimun forfaltaire de 40 euros pour frais de recouvrement sera exigible (Décret 2012-1115 du 9 octobre 2012).</Text>
          </View>

        </View>
      </Page>
    </Document>
  );
};

const tableStyle = {
  display: "table",
  width: "auto",
  marginBottom: '15px',

};

const pricingTableStyle = {
  display: "table",
  width: "auto",
  marginTop: '20px',
  alignItems: 'flex-end',
};

const text = {
  fontSize: '14px',
  marginBottom: '4px'
};

const page = {
  padding: "20px"
};

const tableRowStyle = {
  flexDirection: "row"
};

const firstTableColHeaderStyle = {
  width: "50%",
  borderStyle: "solid",
  borderColor: "#000",
  borderBottomColor: "#000",
  borderWidth: 1,
  backgroundColor: "#bdbdbd"
};

const productTableColHeaderStyle = {
  width: "30%",
  borderStyle: "solid",
  borderColor: "#000",
  borderBottomColor: "#000",
  borderWidth: 1,
  backgroundColor: "#bdbdbd"
};

const tableColHeaderStyle = {
  width: "50%",
  borderStyle: "solid",
  borderColor: "#000",
  borderBottomColor: "#000",
  borderWidth: 1,
  borderLeftWidth: 0,
  backgroundColor: "#bdbdbd"
};

const colHeaderStyle = {
  width: "20%",
  borderStyle: "solid",
  borderColor: "#000",
  borderBottomColor: "#000",
  borderWidth: 1,
  borderLeftWidth: 0,
  backgroundColor: "#bdbdbd"
};

const colHeaderStyleName = {
  width: "30%",
  borderStyle: "solid",
  borderColor: "#000",
  borderBottomColor: "#000",
  borderWidth: 1,
  borderLeftWidth: 0,
  backgroundColor: "#bdbdbd"
};

const colHeaderStyleSKU = {
  width: "30%",
  borderStyle: "solid",
  borderColor: "#000",
  borderBottomColor: "#000",
  borderWidth: 1,
  borderLeftWidth: 0,
  backgroundColor: "#bdbdbd"
};

const firstTableColStyle = {
  width: "50%",
  borderStyle: "solid",
  borderColor: "#000",
  borderWidth: 1,
  borderTopWidth: 0
};

const pricingTableColStyle = {
  width: "25%",
  borderStyle: "solid",
  borderColor: "#000",
  borderWidth: 1,
  borderTopWidth: 1,
  backgroundColor: "#bdbdbd"
};

const tableColStyle = {
  width: "50%",
  borderStyle: "solid",
  borderColor: "#000",
  borderWidth: 1,
  borderLeftWidth: 0,
  borderTopWidth: 0
};

const pricingColStyle = {
  width: "25%",
  borderStyle: "solid",
  borderColor: "#000",
  borderWidth: 1,
  borderLeftWidth: 0,
  borderTopWidth: 1
};

const colStyle = {
  width: "25%",
  borderStyle: "solid",
  borderColor: "#000",
  borderWidth: 1,
  borderLeftWidth: 0,
  borderTopWidth: 0
};

const tableCellHeaderStyle = {
  textAlign: "left",
  margin: 4,
  fontSize: 10,
  fontWeight: "bold"
};

const tableCellStyle = {
  textAlign: "left",
  margin: 5,
  fontSize: 10,
};

export default Pdf;
