import api from "../api/integrations";

// GET INTEGRATION AXEPTIO
export const getAxeptio = async () => {

    return api
        .getAxeptio()
        .then(async (result: any) => {
            if (result.data) {
                return result.data
            }
        })
};

// GET INTEGRATION HOTJAR
export const getHotjar = async () => {

    return api
        .getHotjar()
        .then(async (result: any) => {
            if (result.data) {
                return result.data
            }
        })
};